import React, { useState, useRef, useEffect } from "react";
import "./LoginCard.css"; // CSS file for styling
import OrSeparator from "../../assets/orseparator.svg";
import { useNavigate } from "react-router-dom";
import "react-international-phone/style.css";
import styled from "styled-components";
import { PhoneInput } from "react-international-phone";
import {
  handlePhoneNumberChange,
  requestOTP,
  removeAlert,
  addAlert,
  handleKeyDown,
  handleOTPChange,
  validateOTP,
} from "../../Utils/helper";
import CustomAlert from "../Alerts/CustomAlert";
import useAuth from "../../Hooks/useAuth";
import Button from "../Button/Button";
import { GoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import googleLoginIcon from "../../assets/Logo/googleLogin.svg";
import { open_api_without_auth } from "../../Utils/network";
import LoadingOverlay from "../Loading/LoadingOverlay";
import Cookies from "js-cookie";

const CustomPhoneInput = styled(PhoneInput)`
  flex: 1;
  .react-international-phone-input-container {
    display: flex;
    align-items: center;
    border: none; /* Remove internal borders */
    padding: 0;
    height: 40px; /* Set a height that matches the container */
    background: none; /* No background to blend with container */
  }

  .react-international-phone-input {
    border: none;
    outline: none;
    width: 100%;
    padding: 8px;
    font-size: 13px;
  }

  .react-international-phone-country-selector {
    border-right: 1px solid #ddd; /* Divider between flag and input */
    display: flex;
    align-items: center;
    background-color: #f9f9f9; /* Flag section background */
    height: 100%;
    border-radius: 8px 0 0 8px; /* Rounded corners for the flag section */
  }
`;

export const OtpInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  input {
    width: 48px;
    height: 48px;
    text-align: center;
    font-size: 24px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    input {
      width: 40px;
      height: 40px;
    }
  }
`;

export const ResendOtpLink = styled.a`
  color: #1a73e8;
  text-align: center;
  display: block;
  margin-top: 16px;
  cursor: pointer;
`;

export const OtpContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0;
  width: 100%;
`;

// Login Card Component
const LoginCard = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("in");
  const [isdCode, setIsdCode] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneWithCountryCode, setPhoneWithCountryCode] = useState("");
  const [alerts, setAlerts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [resentOTP, setResentOTP] = useState(false);
  const [otpRequested, setOtpRequested] = useState(false);
  const [otp, setOtp] = useState(Array(4).fill(""));
  const inputRefs = useRef([]);
  const [countdown, setCountdown] = useState(0);
  const [otpValidated, setOtpValidated] = useState(false);
  const { setIsAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handlePhoneInputChange = (value, data) => {
    const sanitizedPhoneValue = data.inputValue.replace(/[^0-9]/g, "");
    handlePhoneNumberChange(
      sanitizedPhoneValue,
      data,
      setIsdCode,
      setPhoneNumber,
      setPhoneNumberError,
      setPhoneWithCountryCode
    );
  };

  const handleOTPInputChange = (e, index) => {
    handleOTPChange(e, index, otp, setOtp, inputRefs);
  };

  const handleOTPKeyDown = (e, index) => {
    handleKeyDown(e, index, otp, inputRefs);
  };

  const handleRequestOTP = () => {
    if (
      phoneNumber === "" ||
      phoneNumber.length < 10 ||
      phoneNumber.length > 10
    ) {
      setShowAlert(true);
      handleAddAlert(
        "Error",
        "Please enter a valid phone number",
        "error",
        true
      );
    }
    setPhoneNumberError(false);
    requestOTP(
      phoneNumber,
      isdCode,
      setOtpRequested,
      setShowAlert,
      handleAddAlert
    );
  };

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0 && resentOTP) {
      setResentOTP(false);
    }
  }, [countdown, resentOTP]);

  const handleSubmitOTP = async () => {
    await handleValidateOTP();
  };

  const handleResendOTP = () => {
    handleRequestOTP();
    setResentOTP(true);
    setCountdown(30);
  };

  const handleValidateOTP = async () => {
    setIsLoading(true);
    const isValidated = await validateOTP(
      otp,
      isdCode,
      phoneNumber,
      setOtpValidated,
      setShowAlert,
      handleAddAlert
    );

    if (isValidated) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    setIsLoading(false);
  };

  const handleGoogleLogin = async () => {
    setIsLoading(true);
    googleLogin();
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setIsLoading(true);
      try {
        const localData = {
          code: tokenResponse.code,
          redirect_uri: window.location.origin + "/login",
        };

        const res = await open_api_without_auth.post(
          `/auth/oauth/google/`,
          localData
        );
        if ([200, 201, 204].includes(res.status)) {
          const userDetailsId = res?.data?.user?.id;
          const accessToken = res?.data?.token?.access;
          const userName = `${res?.data?.user?.first_name} ${res?.data?.user?.last_name}`;
          const expertId = res?.data?.expert?.id;

          Cookies.set("token", accessToken);
          Cookies.set("userName", userName);
          Cookies.set("userId", userDetailsId);
          Cookies.set("expertID", expertId);
          setIsAuthenticated(true);
          return true;
        } else {
          setShowAlert(true);
          handleAddAlert("Login Failed", res.data.message, "error", true);
          setIsAuthenticated(false);
          return false;
        }
      } catch (error) {
        setShowAlert(true);
        handleAddAlert(
          "Login Failed",
          `Google Login Failed, please try again later`,
          "error",
          true
        );
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    },
    onError: () => {
      setShowAlert(true);
      handleAddAlert(
        "Login Failed",
        `Google Login Failed, please try again later`,
        "error",
        true
      );
      setIsAuthenticated(false);
      setIsLoading(false);
    },
    flow: "auth-code", // Use 'auth-code' for the authorization code flow
  });

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <div className="login-card">
        {/* Header */}
        <div className="login-header">
          <h2>Welcome Back 👋</h2>
          <p>
            Today is a new day. It's your day. You shape it.
            <br />
            Sign in to start managing your workshops.
          </p>
        </div>

        {/* Phone Number Field */}
        {otpRequested ? (
          <>
            <h3 style={{ fontWeight: 500 }}>
              A 4-digit code is sent to {`+${isdCode} ${phoneNumber}`}
            </h3>
            <OtpInputContainer>
              {Array.from({ length: 4 }).map((_, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={otp[index]}
                  onChange={(e) => handleOTPInputChange(e, index)}
                  onKeyDown={(e) => handleOTPKeyDown(e, index)}
                  ref={(el) => (inputRefs.current[index] = el)}
                />
              ))}
            </OtpInputContainer>
            <Button
              text="Submit"
              onClick={() => handleSubmitOTP()}
              style={{
                width: "100%",
                backgroundColor: "#FF67A1",
                marginTop: "10px",
              }}
            />
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              {resentOTP ? (
                <div>
                  <p>Resend OTP in {countdown} seconds</p>
                </div>
              ) : (
                <div>
                  <ResendOtpLink onClick={handleResendOTP}>
                    Resend OTP
                  </ResendOtpLink>
                  <p
                    style={{
                      marginTop: "8px",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    Didn't receive a code? Click on Resend to receive OTP
                  </p>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <OtpContainer>
              <CustomPhoneInput
                defaultCountry={countryCode}
                value={phoneNumber}
                disableDialCodeAndPrefix={true}
                onChange={handlePhoneInputChange}
                placeholder="Enter your phone number"
              />
              {phoneNumberError && (
                <div className="error-message">{phoneNumberError}</div>
              )}
            </OtpContainer>
            <Button
              text="Request OTP"
              onClick={() => handleRequestOTP()}
              style={{
                width: "100%",
                backgroundColor: "#FF67A1",
                marginTop: "10px",
              }}
            />
          </>
        )}

        {/* Separator */}
        <div className="separator-login">
          <img src={OrSeparator} className="or-separator" />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={() => handleGoogleLogin()} // Trigger login on button click
            style={{
              backgroundColor: "transparent", // Or any custom color
              border: "none",
              cursor: "pointer",
              padding: "0", // Ensure no extra padding
            }}
          >
            <img
              src={googleLoginIcon} // Path to your SVG
              alt="Google Login"
              style={{ width: "200px", height: "50px" }} // Adjust size as per your needs
            />
          </button>
        </div>

        {/* Footer */}
        <div className="login-footer">&copy; 2024 ALL RIGHTS RESERVED</div>
      </div>
      {showAlert &&
        alerts.map((alert) => (
          <CustomAlert
            key={alert.id}
            title={alert.title}
            message={alert.message}
            onClose={() => handleRemoveAlert(alert.id)}
            autoClose={alert.autoClose}
            type={alert.type}
          />
        ))}
    </>
  );
};

export default LoginCard;
