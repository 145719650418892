import React, { useState } from "react";
import ReactQuill from "react-quill"; // Import the rich text editor
import "react-quill/dist/quill.snow.css"; // Import styles for the editor
import "./RichTextAreaField.css"; // Custom CSS

const RichTextAreaField = ({
  label,
  value,
  onChange,
  name,
  error,
  disabled,
}) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }], // Headers
      ["bold", "italic", "underline", "strike"], // Text styles
      [{ list: "ordered" }, { list: "bullet" }], // Lists
      [{ align: [] }], // Alignments
      ["clean"], // Clear formatting
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "align",
  ];

  return (
    <div className="textarea-field">
      <label className="textarea-label">{label}</label>
      <ReactQuill
        theme="snow"
        value={value} // Controlled value
        onChange={onChange} // Controlled onChange handler
        modules={modules}
        formats={formats}
        className={`rich-textarea ${error ? "textarea-error" : ""}`}
        readOnly={disabled} // Disable editor if needed
      />
      {error && <span className="error-message">{error}</span>}
    </div>
  );
};

export default RichTextAreaField;
