import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import QuestionList from "../../../components/Card/QuestionList"; // Import QuestionList
import backIcon from "../../../assets/Icons/backIcon.svg";
import "./AddQuestions.css";
import DropdownCard from "../../../components/Card/DropdownCard";
import Button from "../../../components/Button/Button";
import { open_api_with_user_token } from "../../../Utils/network"; // API utility import
import Cookies from "js-cookie";
import { addAlert, removeAlert } from "../../../Utils/helper";
import CustomAlert from "../../../components/Alerts/CustomAlert";

const AddQuestions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // Receive questions data from location state
  const {
    questions: initialQuestions,
    sessionPackageTitle,
    isEditMode,
  } = location.state || {};

  // State to manage selected package and questions
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [questions, setQuestions] = useState(
    initialQuestions ? normalizeQuestionsData(initialQuestions) : []
  ); // Initialize with normalized questions
  const [packagesListFromApi, setPackagesListFromApi] = useState([]);
  const [packagesListMap, setPackagesListMap] = useState({});
  const [alerts, setAlerts] = useState([]); // Holds multiple alerts
  const [showAlert, setShowAlert] = useState(false); // Controls visibility of alerts

  useEffect(() => {
    getPackagesData();
  }, []);

  useEffect(() => {
    if (sessionPackageTitle && packagesListFromApi.length > 0) {
      const packageExists = packagesListFromApi.some(
        (pkg) => pkg.title === sessionPackageTitle
      );
      if (packageExists) {
        setSelectedPackage(sessionPackageTitle);
      }
    } else if (isEditMode && sessionPackageTitle) {
      setSelectedPackage(sessionPackageTitle);
    }
  }, [sessionPackageTitle, packagesListFromApi]);

  const getPackagesData = async () => {
    const expertID = Cookies.get("expertID");
    try {
      const res = await open_api_with_user_token.get(
        `/session/dashborad/experts/${expertID}/session-packages-without-questions/`
      );
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        setPackagesListFromApi(res.data.results);
      }
    } catch (error) {
      console.error("Error fetching package data:", error);
    }
  };

  useEffect(() => {
    if (packagesListFromApi.length > 0) {
      const packagesToIdMap = {};
      packagesListFromApi.forEach((item) => {
        packagesToIdMap[item.title] = item.id;
      });
      setPackagesListMap(packagesToIdMap);
    }
  }, [packagesListFromApi]);

  const handleBackClick = () => {
    navigate("/bookings/questions");
  };

  const handleSelect = (selectedOption) => {
    setSelectedPackage(selectedOption);
  };

  // Function to format the questions data
  const formatQuestionsData = (packageId, expertID, questions) => {
    return questions.map((question, index) => ({
      id: question.id || null, // Include id if it exists (for edit mode)
      session_package: packageId,
      expert: expertID,
      text: question.text,
      question_type:
        question.type.toLowerCase() === "multiple choice"
          ? "multiple_choice"
          : "text",
      is_required: question.isRequired,
      order: index + 1,
      options: question.options.map((option) => ({
        text: option.text,
      })), // Keep the id in case of edit mode
    }));
  };

  const handleSaveClick = async () => {
    if (!selectedPackage) {
      handleAddAlert(
        "Action Unsuccessful",
        "Please select a package.",
        "error",
        true
      );
      return;
    }

    const expertID = Cookies.get("expertID");
    const packageId = packagesListMap[selectedPackage];
    const formattedQuestionsData = formatQuestionsData(
      packageId,
      expertID,
      questions
    );

    try {
      const successfulQuestions = [];
      const failedQuestions = [];

      // Iterate through each formatted question
      for (const question of formattedQuestionsData) {
        try {
          let response;

          // Check if the question is a new one based on the id format
          if (
            isEditMode &&
            question.id &&
            question.id.startsWith("new-question-")
          ) {
            // Call the alternate API for newly added questions in edit mode
            response = await open_api_with_user_token.post(
              `/session/questions/`,
              {
                session_package: question.session_package,
                expert: question.expert,
                text: question.text,
                question_type: question.question_type,
                is_required: question.is_required,
                order: question.order,
                options_input: question.options.map((option) => option.text), // Send only text array for adding new question
              }
            );
          } else if (isEditMode && question.id) {
            // Update existing question
            response = await open_api_with_user_token.patch(
              `/session/questions/${question.id}/`,
              {
                session_package: question.session_package,
                expert: question.expert,
                text: question.text,
                question_type: question.question_type,
                is_required: question.is_required,
                order: question.order,
              }
            );
            for (const option of question.options) {
              if (option.id) {
                // Update existing option
                try {
                  const optionResponse = await open_api_with_user_token.patch(
                    `/session/options/${option.id}/`,
                    { question: question.id, text: option.text }
                  );
                  if (
                    optionResponse.status !== 200 &&
                    optionResponse.status !== 201
                  ) {
                    failedQuestions.push(question);
                  }
                } catch (error) {
                  failedQuestions.push(question);
                }
              } else {
                // Create new option if id does not exist
                try {
                  const newOptionResponse = await open_api_with_user_token.post(
                    `/session/options/`,
                    { question: question.id, text: option.text }
                  );
                  if (
                    newOptionResponse.status !== 200 &&
                    newOptionResponse.status !== 201
                  ) {
                    failedQuestions.push(question);
                  }
                } catch (error) {
                  failedQuestions.push(question);
                }
              }
            }
          } else {
            // Create new question
            response = await open_api_with_user_token.post(
              `/session/questions/`,
              {
                session_package: question.session_package,
                expert: question.expert,
                text: question.text,
                question_type: question.question_type,
                is_required: question.is_required,
                order: question.order,
                options_input: question.options.map((option) => option.text), // Send only text array for adding new question
              }
            );
          }

          // Check response status
          if (response.status === 200 || response.status === 201) {
            successfulQuestions.push({
              ...question,
              id: response.data.id || question.id, // Store newly created question id or keep existing
            });
          }
        } catch (error) {
          failedQuestions.push(question);
        }
      }

      if (failedQuestions.length === 0) {
        navigate("/bookings/questions", {
          state: {
            status: "success",
            message: isEditMode
              ? "All questions updated successfully"
              : "All questions successfully added.",
          },
        });
      } else {
        handleAddAlert(
          "Action Unsuccessful",
          "Some questions could not be saved. Please try again.",
          "error",
          true
        );
      }
    } catch (error) {
      console.error("Failed to submit questions:", error);
    }
  };

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
    setShowAlert(true); // Show alert when adding a new alert
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
    if (alerts.length <= 1) {
      setShowAlert(false); // Hide alert if there are no more alerts to show
    }
  };

  return (
    <div className="main-container">
      <div className="add-questions-container">
        <div className="add-questions-header">
          <div className="add-questions-header-left">
            <img
              src={backIcon}
              alt="back icon"
              className="add-questions-back-icon"
              onClick={handleBackClick}
            />
            <h2>Add Registration Questions</h2>
          </div>
          <div className="add-questions-header-right ">
            <Button text="Save" onClick={handleSaveClick} />
          </div>
        </div>
        <div className="add-questions-content">
          <DropdownCard
            options={packagesListFromApi.map((item) => item.title)}
            title="Select a package"
            onSelect={handleSelect}
            selected={sessionPackageTitle}
          />
          <QuestionList questions={questions} setQuestions={setQuestions} />
        </div>
      </div>
      {showAlert &&
        alerts.map((alert) => (
          <CustomAlert
            key={alert.id}
            title={alert.title}
            message={alert.message}
            onClose={() => handleRemoveAlert(alert.id)}
            autoClose={alert.autoClose}
            type={alert.type}
          />
        ))}
    </div>
  );
};

// Function to map the API data to the required format
const normalizeQuestionsData = (questions) => {
  return questions.map((question, index) => ({
    id: question.id ? question.id.toString() : `question-${index}`, // Fallback to index-based ID
    text: question.text,
    type:
      question.question_type === "multiple_choice" ? "Multiple choice" : "Text",
    isRequired: question.is_required,
    options: question.options.map((option, optIndex) => ({
      id: option.id ? option.id.toString() : `option-${index}-${optIndex}`, // Fallback to combined index ID
      text: option.text,
    })),
  }));
};

export default AddQuestions;
