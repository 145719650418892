import React from "react";
import "./SubmitButton.css"; // Import the CSS file for styling

const SubmitButton = ({ text, onClick, style }) => {
  return (
    <button className="submit-button" onClick={onClick} style={style}>
      {text}
    </button>
  );
};

export default SubmitButton;
