import React, { useState, useEffect } from "react";
import CustomAlert from "../../../components/Alerts/CustomAlert";
import { addAlert, removeAlert } from "../../../Utils/helper";
import styles from "./BookingSettings.module.css"; // Import CSS module
import Cookies from "js-cookie";
import {
  open_api_with_user_token,
  open_api_without_auth,
} from "../../../Utils/network";
import TextAreaField from "../../../components/fields/TextAreaField";
import editIcon from "../../../assets/Icons/editIcon.svg";
import { gapi } from "gapi-script";
import { useGoogleLogin } from "@react-oauth/google";
import ToggleButton from "../../../components/Button/ToggleButton";
import Button from "../../../components/Button/Button";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";

const BookingSettings = () => {
  const [alerts, setAlerts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [expertData, setExpertData] = useState([]);
  const [sessionInfo, setSessionInfo] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [calendarToggles, setCalendarToggles] = useState({
    Family: true,
    Office: false,
  });
  const [calendars, setCalendars] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [newAccount, setNewAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchLinkedAccounts();
  }, []);

  const fetchLinkedAccounts = async () => {
    try {
      const res = await open_api_with_user_token.get(
        "/profiles/google-accounts/"
      );
      setLinkedAccounts(res.data);
      if (res.data.length > 0) {
        setSelectedAccount(res.data[0].id);
        fetchCalendars(res.data[0].id, res.data[0].linked_email);
      }
    } catch (error) {
      console.error("Failed to fetch linked accounts:", error);
    }
  };

  const googleCalendarLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setIsLoading(true);
      try {
        const localData = {
          code: tokenResponse.code,
          redirect_uri: window.location.origin,
        };

        const res = await open_api_with_user_token.post(
          `/auth/google-calendar/`,
          localData
        );

        if ([200, 201].includes(res.status)) {
          setIsLoading(false);
          handleAddAlert(
            "Success",
            "Google Calendar added successfully!",
            "success",
            true
          );
          setIsAuthenticated(true);
        } else {
          setIsLoading(false);
          handleAddAlert(
            "Failed",
            "Failed to authenticate Google Calendar.",
            "error",
            true
          );
        }
      } catch (error) {
        setIsLoading(false);
        handleAddAlert(
          "Failed",
          "Google Calendar authentication failed. Please try again later.",
          "error",
          true
        );
      } finally {
        setIsLoading(false);
      }
    },
    onError: () => {
      setIsLoading(false);
      handleAddAlert(
        "Failed",
        "Google Calendar authentication failed. Please try again later.",
        "error",
        true
      );
    },
    flow: "auth-code", // Use authorization code flow
    scope: "https://www.googleapis.com/auth/calendar",
  });

  useEffect(() => {
    getExpertData();
  }, []);

  const getExpertData = async () => {
    const expertID = Cookies.get("expertID");
    try {
      const res = await open_api_with_user_token.get(
        `/home/experts/${expertID}/`
      );
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        setExpertData(res.data.data);
        setSessionInfo(res.data.data.about_session_offered);
      }
    } catch (error) {
      console.error("Error fetching booking stats data", error);
    }
  };

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
    setShowAlert(true);
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
    if (alerts.length <= 1) setShowAlert(false);
  };

  const handleSaveSessionInfo = async () => {
    const expertID = Cookies.get("expertID");
    const localData = {
      about_session_offered: sessionInfo,
    };
    try {
      const res = await open_api_with_user_token.patch(
        `/home/experts/${expertID}/`,
        localData
      );
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        setIsEditing(false);
        handleAddAlert(
          "Success",
          "Session information updated successfully!",
          "success",
          true
        );
      }
    } catch (error) {
      console.error("Error fetching booking stats data", error);
    }
  };

  const handleToggleCalendar = (calendar) => {
    setCalendarToggles((prevState) => ({
      ...prevState,
      [calendar]: !prevState[calendar],
    }));
  };

  const fetchCalendars = async (accountId, linked_email) => {
    // Find the linked email for the selected account
    const selectedEmail =
      linkedAccounts.find((acc) => acc.id === accountId)?.linked_email ||
      linked_email;

    if (!selectedEmail) {
      console.error(
        "Selected email not found for account ID:",
        selectedAccount
      );
      return;
    }

    try {
      const response = await open_api_with_user_token.get(
        `session/calendars/?linked_email=${selectedEmail}`
      );

      const data = response.data.results;
      setCalendars(data);
    } catch (error) {
      console.error("Error fetching calendars:", error);
    }
  };

  const addGoogleAccount = async () => {
    setIsLoading(true);
    setNewAccount(true);
    googleCalendarLogin();
  };

  const updateCalendarSync = async (calendarId, isEnabled) => {
    // Optimistically update the state
    setCalendars((prevCalendars) =>
      prevCalendars.map((calendar) =>
        calendar.id === calendarId
          ? { ...calendar, is_enabled: isEnabled }
          : calendar
      )
    );

    try {
      const response = await open_api_with_user_token.post(
        "session/calendars/update/",
        { calendar_id: calendarId, is_enabled: isEnabled }
      );

      if (response.status !== 200) {
        // Revert the state if the API call fails
        console.error("Failed to update calendar sync:", response);
        setCalendars((prevCalendars) =>
          prevCalendars.map((calendar) =>
            calendar.id === calendarId
              ? { ...calendar, is_enabled: !isEnabled }
              : calendar
          )
        );
      }
    } catch (error) {
      // Revert the state if there's an error
      console.error("Error updating calendar sync:", error);
      setCalendars((prevCalendars) =>
        prevCalendars.map((calendar) =>
          calendar.id === calendarId
            ? { ...calendar, is_enabled: !isEnabled }
            : calendar
        )
      );
    }
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <div className={styles.mainContainer}>
        <div className={styles.settingsPage}>
          {showAlert &&
            alerts.map((alert) => (
              <CustomAlert
                key={alert.id}
                title={alert.title}
                message={alert.message}
                onClose={() => handleRemoveAlert(alert.id)}
                autoClose={alert.autoClose}
                type={alert.type}
              />
            ))}

          {/* General Section */}
          <div className={styles.settingsSection}>
            <h3>General Settings</h3>
            <div className={styles.card}>
              <div className={styles.cardHeaderGeneral}>
                <p>About the services that you offer</p>
                <button
                  onClick={() => setIsEditing(!isEditing)}
                  className={styles.editButton}
                  aria-label="Edit"
                >
                  <img src={editIcon} alt="Edit" />
                </button>
              </div>
              <div className={styles.cardBody}>
                {isEditing ? (
                  <TextAreaField
                    label="About the Sessions offered"
                    value={sessionInfo}
                    name="aboutTheSession"
                    onChange={(e) => setSessionInfo(e.target.value)}
                    disabled={!isEditing}
                  />
                ) : (
                  <TextAreaField
                    label="About the Sessions offered"
                    value={sessionInfo}
                    name="aboutTheSession"
                    onChange={(e) => setSessionInfo(e.target.value)}
                    disabled={true}
                  />
                )}
              </div>
              {isEditing && (
                <div className={styles.cardFooter}>
                  <button
                    onClick={handleSaveSessionInfo}
                    className={styles.saveButton}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Connect to Calendar Section */}
          <div className={styles.settingsSection}>
            <h3>Connect to calendar</h3>
            <div className={styles.card}>
              <div className={styles.googleCalendarRow}>
                <div className={styles.googleCalendarInfo}>
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Google_Calendar_icon_%282020%29.svg"
                    alt="Google Calendar"
                    className={styles.googleCalendarLogo}
                  />
                  <p>Connect your Google Calendar</p>
                </div>
                <Button
                  text="+ Connect new Google account"
                  onClick={addGoogleAccount}
                  style={{
                    backgroundColor: "#FF67A1",
                    fontSize: "12px",
                  }}
                />
              </div>
              {linkedAccounts.length > 0 && (
                <div>
                  <h4>Linked Accounts</h4>
                  <select
                    value={selectedAccount || ""}
                    onChange={(e) => {
                      const newSelectedAccount = parseInt(e.target.value, 10); // Convert to number
                      setSelectedAccount(newSelectedAccount); // Update the state
                      fetchCalendars(newSelectedAccount); // Fetch the account's calendars
                    }}
                    className={styles.dropdown}
                  >
                    <option value="">Select an account</option>
                    {linkedAccounts.map((account) => (
                      <option key={account.id} value={account.id}>
                        {account.linked_email}
                      </option>
                    ))}
                  </select>
                  {selectedAccount && (
                    <div>
                      <h4>
                        Calendars for{" "}
                        {
                          linkedAccounts.find(
                            (acc) => acc.id === selectedAccount
                          )?.linked_email
                        }
                      </h4>
                      <ul className={styles.calendarList}>
                        {calendars.map((calendar) => (
                          <li key={calendar.id} className={styles.calendarItem}>
                            <span>{calendar.calendar_name}</span>
                            <ToggleButton
                              isToggled={calendar.is_enabled}
                              onToggle={() =>
                                updateCalendarSync(
                                  calendar.id,
                                  !calendar.is_enabled
                                )
                              }
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingSettings;
