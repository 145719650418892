import React, { useState, useEffect } from "react";
import "./AllBookingsPage.css";
import TableCard from "../../../components/Card/TableCard";
import FilterChip from "../../../components/Chip/FilterChip";
import DateRangeChip from "../../../components/Chip/DateRangeChip";
import SearchBar from "../../../components/Search/SearchBar";
import { open_api_with_user_token } from "../../../Utils/network";
import { formatDate, formatSlotTime } from "../../../Utils/helper";
import Cookies from "js-cookie";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";

const AllBookingsPage = () => {
  const [selectedOption, setSelectedOption] = useState("All");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [options, setOptions] = useState(["All"]);
  const [isLoading, setLoading] = useState(false);

  const columns = [
    { header: "Customer Name", accessor: "customerName" },
    { header: "Package", accessor: "package" },
    { header: "Date", accessor: "date" },
    { header: "Slot", accessor: "slot" },
    { header: "Status", accessor: "status" },
  ];

  // Fetch packages for filter options
  const fetchPackageData = async () => {
    setLoading(true);
    try {
      const expertId = Cookies.get("expertID");
      const res = await open_api_with_user_token.get(
        `session/all-packages/?expert=${expertId}`
      );
      if (res.status === 200) {
        const apiData = res.data.data;
        const titles = apiData.map((item) => item.title);
        setOptions(["All", ...titles]);
      }
    } catch (error) {
      console.error("Error fetching package data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPackageData();
  }, []);

  // Transform API data for the table
  const mapApiDataToTableData = (apiData) => {
    return apiData
      .map((booking) => {
        const {
          id,
          package: packageInfo,
          booked_slots,
          user,
          payment_status_str,
        } = booking;

        const customerName = `${user.first_name} ${user.last_name}`;
        const packageTitle = packageInfo.title;

        const slotDetails = booked_slots.map((slotData) => {
          const { slot } = slotData;
          const slotDate = slot.date;
          const slotTime = slot.time;
          const slotDuration = slot.duration;

          const formattedSlot = formatSlotTime(
            slotDate,
            slotTime,
            slotDuration
          );

          return {
            id: slotData.id,
            customerName: customerName,
            package: packageTitle,
            date: formatDate(slotDate),
            slot: formattedSlot,
            status: payment_status_str,
          };
        });

        return slotDetails;
      })
      .flat(); // Flatten array to ensure all slot details are on the same level
  };

  // Fetch bookings data based on filters and pagination
  const fetchBookingData = async () => {
    setLoading(true);
    try {
      setTableData([]);
      let query = `/session/user_purchased_list?page=${currentPage}`;

      if (selectedOption !== "All") {
        query += `&package_title=${selectedOption}`;
      }

      if (startDate && endDate) {
        const formattedStartDate = new Date(startDate)
          .toISOString()
          .split("T")[0];
        const formattedEndDate = new Date(endDate).toISOString().split("T")[0];
        query += `&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
      }

      if (searchQuery) {
        query += `&search=${searchQuery}`;
      }

      const res = await open_api_with_user_token.get(query);
      if (res.status === 200) {
        const apiData = res.data.data;
        const transformedData = mapApiDataToTableData(apiData);
        setTableData(transformedData);
        setTotalPages(res.data.max_pages);
        setTotalEntries(transformedData.length);
      }
    } catch (error) {
      console.error("Error fetching booking data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBookingData();
  }, [selectedOption, startDate, endDate, searchQuery, currentPage]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setCurrentPage(1);
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    setCurrentPage(1);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="main-container">
      <div className="all-bookings-container">
        <h2>All Bookings</h2>
        <div className="chips-bookings-container">
          <div className="filters-container">
            <FilterChip
              options={options}
              selected={selectedOption}
              onSelect={handleSelect}
            />
            <DateRangeChip
              label="Select Date Range"
              onDateChange={handleDateChange}
            />
          </div>
          <div className="search-container">
            <SearchBar onSearch={handleSearch} />
          </div>
        </div>

        <div className="all-bookings-table-container">
          <TableCard
            columns={columns}
            data={tableData}
            totalEntries={totalEntries}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            isClickable={true}
            loading={isLoading}
            isShowJoinButton={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AllBookingsPage;
