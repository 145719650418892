import React, { useState, useEffect } from "react";
import SessionCard from "../../../components/Card/SessionCard";
import "./AllPackage.css";
import Chips from "../../../components/Chip/Chips";
import addIcon from "../../../assets/Icons/plusIcon.svg";
import AddPackage from "./AddPackage";
import { getDurationInMins } from "../../../Utils/helper";
import { open_api_with_user_token } from "../../../Utils/network";
import Cookies from "js-cookie";
import { addAlert, removeAlert } from "../../../Utils/helper";
import CustomAlert from "../../../components/Alerts/CustomAlert";
import LoadingOverlay from "../../../components/Loading/LoadingOverlay";

const AllPackage = () => {
  // State to manage modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [packagesList, setPackagesList] = useState([]);
  const [packagesListFromApi, setPackagesListFromApi] = useState(null);
  const [alerts, setAlerts] = useState([]); // Holds multiple alerts
  const [showAlert, setShowAlert] = useState(false); // Controls visibility of alerts
  const [refreshKey, setRefreshKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [expertSlug, setExpertSlug] = useState("");
  const formData = {
    sessionName: "",
    aboutPackage: "",
    sessionsPerPackage: "",
    duration: "15 mins",
    priceInr: "",
    priceUsd: "",
  };

  useEffect(() => {
    getPackagesData();
    getExpertInfo();
  }, [refreshKey]);

  const getPackagesData = async () => {
    setIsLoading(true);
    const expertID = Cookies.get("expertID");
    try {
      const res = await open_api_with_user_token.get(
        `/session/all-packages/?expert=${expertID}`
      );
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        setPackagesListFromApi(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching booking stats data", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getExpertInfo = async () => {
    const expertID = Cookies.get("expertID");
    try {
      const res = await open_api_with_user_token.get(
        `/profiles/expert/${expertID}/`
      );
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        setExpertSlug(res.data.slug);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (packagesListFromApi) {
      const formattedPackageList = mapApiDataToPackageList(packagesListFromApi);
      setPackagesList(formattedPackageList);
    }
  }, [packagesListFromApi]);

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
    setShowAlert(true); // Show alert when adding a new alert
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
    if (alerts.length <= 1) {
      setShowAlert(false); // Hide alert if there are no more alerts to show
    }
  };

  const mapApiDataToPackageList = (packagesListFromApi) => {
    return packagesListFromApi.map((sessionPackage) => {
      const durationInMins = sessionPackage.slot_duration.duration_name
        ? sessionPackage.slot_duration.duration_name
        : "N/A"; // Fallback if duration is null or undefined

      return {
        id: sessionPackage.id,
        title: sessionPackage.title,
        duration: durationInMins, // Use formatted or fallback duration
        sessions: `${sessionPackage.sessions_count} sessions`,
        isActive: sessionPackage.published,
        details: [
          {
            label: "Selling price",
            value: `₹ ${sessionPackage.selling_price}`,
          },
          {
            label: "Selling price USD",
            value: sessionPackage.selling_price_usd
              ? `$ ${sessionPackage.selling_price_usd}`
              : "N/A", // Fallback for USD price
          },
        ],
        is_user_data_required: sessionPackage.is_user_data_required,
      };
    });
  };

  // Sample session data
  const sessionData = {
    title: "1 Session package",
    duration: "60Mins",
    sessions: "1 session",
    isActive: true,
    details: [
      { label: "Selling price", value: "₹4000" },
      { label: "Selling price USD", value: "$4000" },
    ],
  };

  const handleSubmitStatus = (status, message) => {
    setRefreshKey((prev) => prev + 1);
    if (status === "success") {
      handleAddAlert("Action Successful", message, "success", true);
    } else {
      handleAddAlert("Action Failure", message, "error", true);
    }
  };

  // Function to toggle the modal state
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <div className="main-container">
        <div className="all-packages-container">
          <div className="all-packages-header">
            <div className="all-packages-header-left">
              <h2>All Packages</h2>
            </div>
            <div className="all-packages-header-right">
              {/* Use Chips component with onClick to open modal */}
              <Chips icon={addIcon} text="Add Packages" onClick={toggleModal} />
            </div>
          </div>
          {/* Render multiple SessionCards */}

          {packagesList.map((packageData) => (
            <SessionCard
              data={packageData}
              handleSubmitStatus={handleSubmitStatus}
              cardFormData={formData}
              expertSlug={expertSlug}
            />
          ))}

          {/* Conditionally render AddPackage modal */}
          {isModalOpen && (
            <AddPackage
              onClose={toggleModal}
              onSubmitStatus={handleSubmitStatus}
              parentFormData={formData}
            />
          )}
        </div>
        {showAlert &&
          alerts.map((alert) => (
            <CustomAlert
              key={alert.id}
              title={alert.title}
              message={alert.message}
              onClose={() => handleRemoveAlert(alert.id)}
              autoClose={alert.autoClose}
              type={alert.type}
            />
          ))}
      </div>
    </>
  );
};

export default AllPackage;
