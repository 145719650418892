import React from "react";
import "./SideBarDialog.css";

const SideBarDialog = ({ isVisible, onClose, title, children, footer }) => {
  return (
    <div className={`sidebar-container ${!isVisible ? "slide-out" : ""}`}>
      <div className="sidebar-header">
        <h2>{title}</h2>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
      </div>
      <div className="sidebar-content">{children}</div>
      {footer && <div className="sidebar-footer">{footer}</div>}
    </div>
  );
};

export default SideBarDialog;
