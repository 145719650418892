import React, { useState, useEffect } from "react";
import Modal from "../../../components/Dialog/Modal";
import DateField from "../../../components/fields/DateField";
import MultiSelectField from "../../../components/fields/MultiSelectField";
import TimeField from "../../../components/fields/TimeField";
import DropdownField from "../../../components/fields/DropdownField";
import SubmitButton from "../../../components/Button/SubmitButton";
import { open_api_with_user_token } from "../../../Utils/network"; // Import your API utility
import Cookies from "js-cookie";
import moment from "moment-timezone";
import { getDurationInMins } from "../../../Utils/helper";
import "./AllSlots.css";

const AddSlots = ({ onClose, onSubmitStatus }) => {
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    selectedDays: [],
    startTime: null,
    endTime: null,
    duration: "Please add a package before creating slots",
  });

  const [formErrors, setFormErrors] = useState({}); // State for validation errors
  const [selectedDays, setSelectedDays] = useState([]);
  const [showMultiSelect, setShowMultiSelect] = useState(false);
  const [packageList, setpackageList] = useState([]);
  const [packageMap, setPackageMap] = useState({});

  // Calculate maxDate which is 2 months from today
  const maxDate = moment().add(2, "months").toDate();

  useEffect(() => {
    getPackagesData();
  }, []);

  const getPackagesData = async () => {
    const expertID = Cookies.get("expertID");
    try {
      const res = await open_api_with_user_token.get(
        `/session/all-packages/?expert=${expertID}`
      );
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        const packagesListData = res.data.data;
        setpackageList(packagesListData);
        formData.duration = packagesListData[0].title;
        const packageNameToDurationMap = {};
        packagesListData.forEach((item) => {
          packageNameToDurationMap[item.title] = item.slot_duration.duration;
        });
        setPackageMap(packageNameToDurationMap);
      }
    } catch (error) {
      console.error("Error fetching booking stats data", error);
    } finally {
    }
  };

  useEffect(() => {
    if (formData.startDate && formData.endDate) {
      const start = moment(formData.startDate);
      const end = moment(formData.endDate);
      const daysDiff = end.diff(start, "days") + 1;

      if (daysDiff >= 7) {
        setShowMultiSelect(true); // Show MultiSelectField
      } else {
        setShowMultiSelect(false); // Hide MultiSelectField and set selectedDays to all days
        setSelectedDays(["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]);
      }
    } else {
      setShowMultiSelect(false); // Reset MultiSelectField when dates are not selected
    }
  }, [formData.startDate, formData.endDate]);

  const handleDateChange = (date, key) => {
    setFormData({ ...formData, [key]: date });
  };

  const handleSelectDay = (days) => {
    setSelectedDays(days);
    setFormData({ ...formData, selectedDays: days });
  };

  const handleDropdownChange = (selectedValue) => {
    setFormData({ ...formData, duration: selectedValue });
  };

  // Validate form fields
  const validateForm = () => {
    const errors = {};
    const currentDateTime = moment(); // Get the current date and time
    const currentDate = moment().startOf("day");

    // Start date validation
    if (!formData.startDate) {
      errors.startDate = "Start date is required";
    }

    // End date validation
    if (!formData.endDate) {
      errors.endDate = "End date is required";
    } else if (moment(formData.startDate).isAfter(formData.endDate)) {
      errors.endDate = "End date must be after the start date";
    }

    // Start time validation
    if (!formData.startTime) {
      errors.startTime = "Start time is required";
    }

    // End time validation
    if (!formData.endTime) {
      errors.endTime = "End time is required";
    } else if (
      formData.startDate &&
      formData.endDate &&
      moment(formData.startDate).isSame(formData.endDate) &&
      moment(formData.startTime, "HH:mm").isAfter(
        moment(formData.endTime, "HH:mm")
      )
    ) {
      errors.endTime = "End time must be after start time on the same day";
    }

    if (formData.startDate && formData.startTime) {
      const startDate = moment(formData.startDate).format("YYYY-MM-DD");
      const time = moment(formData.startTime).format("HH:mm");
      const startDateTime = moment(`${startDate} ${time}`, "YYYY-MM-DD HH:mm");

      if (startDateTime.isBefore(currentDateTime)) {
        if (
          moment(formData.startDate).isSame(currentDate, "day") &&
          startDateTime.isBefore(currentDateTime)
        ) {
          errors.startTime = "Start time must be in the future";
        }
      }
    }

    // Duration validation
    if (
      !formData.duration ||
      formData.duration === "Please add a package before creating slots"
    ) {
      errors.duration = "Duration is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      onSubmitStatus &&
        onSubmitStatus(
          "failure",
          "Please fill out all required fields correctly."
        );
      return; // Exit if form is not valid
    }
    try {
      // If MultiSelectField is not shown, ensure all days are selected
      const daysToSend = showMultiSelect
        ? selectedDays
        : ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      const formattedData = formatFormData({
        ...formData,
        selectedDays: daysToSend,
      });

      const expertId = Cookies.get("expertID");
      const payload = { ...formattedData, expert_id: expertId };

      // API call to submit the slot data
      const response = await open_api_with_user_token.post(
        "session/expert_slot/create_slots/",
        payload
      );

      if (response.status === 200 || response.status === 201) {
        onSubmitStatus &&
          onSubmitStatus(
            "success",
            response?.data?.data?.message || "Slots added successfully"
          );
      } else if (response.status === 400) {
        if (response.data.error) {
          onSubmitStatus("failure", response.data.error);
        }
      } else {
        onSubmitStatus &&
          onSubmitStatus("failure", "Failed to add slots, please try again.");
      }
      onClose();
    } catch (error) {
      console.error("Failed to submit slot data:", error);
      onSubmitStatus &&
        onSubmitStatus(
          "failure",
          "Failed to submit slot data, please try again."
        );
    }
  };

  const formatFormData = (data) => {
    const timeZone = "Asia/Kolkata";
    const startDate = data.startDate
      ? moment(data.startDate).tz(timeZone).format("YYYY-MM-DD")
      : null;
    const endDate = data.endDate
      ? moment(data.endDate).tz(timeZone).format("YYYY-MM-DD")
      : null;

    const startTime = data.startTime
      ? moment(data.startTime).tz(timeZone).format("HH:mm:ss")
      : null;
    const endTime = data.endTime
      ? moment(data.endTime).tz(timeZone).format("HH:mm:ss")
      : null;

    const duration = getDurationInMins(packageMap[data.duration]);
    const daysMap = {
      Mon: "mon",
      Tue: "tue",
      Wed: "wed",
      Thu: "thu",
      Fri: "fri",
      Sat: "sat",
      Sun: "sun",
    };
    const days = data.selectedDays.map((day) => daysMap[day]);

    return {
      start_date: startDate,
      end_date: endDate,
      start_time: startTime,
      end_time: endTime,
      duration: duration,
      days: days,
    };
  };

  return (
    <Modal title="Create Session Slots" onClose={onClose}>
      {/* Start Date Field */}
      <DateField
        label="Create Slots from date"
        selectedDate={formData.startDate}
        onChange={(date) => handleDateChange(date, "startDate")}
        minDate={new Date()}
        maxDate={maxDate}
      />
      {/* Error Message for Start Date */}
      {formErrors.startDate && (
        <div className="error-message">{formErrors.startDate}</div>
      )}

      {/* End Date Field */}
      <DateField
        label="Create Slots until date"
        selectedDate={formData.endDate}
        onChange={(date) => handleDateChange(date, "endDate")}
        minDate={formData.startDate} // Ensure end date cannot be before start date
        maxDate={maxDate}
      />
      {/* Error Message for End Date */}
      {formErrors.endDate && (
        <div className="error-message">{formErrors.endDate}</div>
      )}

      {/* MultiSelectField or Hidden Placeholder */}
      {showMultiSelect && (
        <>
          <MultiSelectField
            selectedDays={selectedDays}
            onSelectDay={handleSelectDay}
          />
          {formErrors.selectedDays && (
            <div className="error-message">{formErrors.selectedDays}</div>
          )}
        </>
      )}

      {/* Start Time Field */}
      <TimeField
        label="Start time of your availability"
        selectedTime={formData.startTime}
        onChange={(date) => handleDateChange(date, "startTime")}
      />
      {/* Error Message for Start Time */}
      {formErrors.startTime && (
        <div className="error-message">{formErrors.startTime}</div>
      )}

      {/* End Time Field */}
      <TimeField
        label="End time of your availability"
        selectedTime={formData.endTime}
        onChange={(date) => handleDateChange(date, "endTime")}
      />
      {/* Error Message for End Time */}
      {formErrors.endTime && (
        <div className="error-message">{formErrors.endTime}</div>
      )}

      {/* Duration Dropdown Field */}
      <DropdownField
        label="Select the package to assign these slots"
        options={packageList.map((item) => item.title)}
        value={formData.duration}
        onChange={handleDropdownChange}
      />
      {/* Error Message for Duration */}
      {formErrors.duration && (
        <div className="error-message">{formErrors.duration}</div>
      )}

      {/* Submit Button */}
      <SubmitButton text="Submit" onClick={handleSubmit} />
    </Modal>
  );
};

export default AddSlots;
