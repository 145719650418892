import React from "react";
import "./TextAreaField.css";

const TextAreaField = ({ label, value, onChange, name, error, disabled }) => {
  return (
    <div className="textareaField-field">
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        className={`textareaField ${error ? "textareaField-error" : ""}`}
        rows="5" // Adjust rows as needed for the height
        required
        disabled={disabled}
      />
      <label className={value ? "filled" : ""}>{label}</label>
      {error && <span className="error-message">{error}</span>}{" "}
    </div>
  );
};

export default TextAreaField;
