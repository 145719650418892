import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
import logo from "../../assets/Logo/myburgoLogo.svg";
import profilePic from "../../assets/profilepicture.png";
import dashboardIcon from "../../assets/Icons/dashboardIcon.svg";
import bookingsIcon from "../../assets/Icons/bookingsIcon.svg";
import arrowright from "../../assets/Icons/chevron-right.svg";
import Button from "../Button/Button";
import Modal from "../Dialog/Modal";
import useAuth from "../../Hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import settingsIcon from "../../assets/Icons/settingsIcon.svg";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const [isBookingsOpen, setIsBookingsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { logout } = useAuth();
  const [expertName, setExpertName] = useState("");

  // Retrieve the expert name from cookies
  useEffect(() => {
    const nameFromCookies = Cookies.get("userName") || "Guest";
    setExpertName(nameFromCookies);
  }, []);

  // Toggle submenu open/close
  const toggleBookingsMenu = () => {
    setIsBookingsOpen(!isBookingsOpen);
  };

  const toggleSettingsMenu = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  // Function to check if a path is active or matches a sub-route
  const isActive = (path) => {
    return location.pathname === path;
  };

  useEffect(() => {
    if (location.pathname.startsWith("/bookings")) {
      setIsBookingsOpen(true);
    }
  }, [location.pathname]);

  const cancelLogout = () => {
    setShowConfirmModal(false); // Close confirmation modal
  };

  const confirmLogout = () => {
    logout();
    setShowConfirmModal(false); // Close confirmation modal
  };

  const handleLogout = () => {
    setShowConfirmModal(true);
  };

  // Close sidebar when a link is clicked
  const handleLinkClick = () => {
    toggleSidebar();
  };

  return (
    <>
      {/* Mobile toggle button */}
      <button
        className={`close-sidebar ${isOpen ? "open" : ""}`}
        onClick={toggleSidebar}
      >
        &times;
      </button>

      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        {/* Logo Section */}
        <div className="logo-section">
          <img src={logo} alt="Logo" className="logo" />
        </div>

        {/* Menu Options */}
        <nav className="menu">
          <ul>
            <li
              className={isActive("/") ? "active" : ""}
              onClick={handleLinkClick}
            >
              <Link to="/">
                <span className="icon">
                  <img src={dashboardIcon} alt="Dashboard Icon" />
                </span>
                Dashboard
                <span className="arrow">
                  <img src={arrowright} alt="arrow right" />
                </span>
              </Link>
            </li>

            <li
              className={`${
                isActive("/bookings") || isBookingsOpen ? "open" : ""
              }`}
            >
              <div className="menu-item" onClick={toggleBookingsMenu}>
                <span className="icon">
                  <img src={bookingsIcon} alt="Bookings Icon" />
                </span>
                1 on 1 Bookings
                <span className={`arrow ${isBookingsOpen ? "open" : ""}`}>
                  <img src={arrowright} alt="arrow right" />
                </span>
              </div>
              {(isBookingsOpen || isActive("/bookings")) && (
                <ul className="submenu">
                  <li
                    className={isActive("/bookings/dashboard") ? "active" : ""}
                    onClick={handleLinkClick}
                  >
                    <Link to="/bookings/dashboard">Bookings Dashboard</Link>
                  </li>
                  <li
                    className={isActive("/bookings/all") ? "active" : ""}
                    onClick={handleLinkClick}
                  >
                    <Link to="/bookings/all">All Bookings</Link>
                  </li>
                  <li
                    className={isActive("/bookings/packages") ? "active" : ""}
                    onClick={handleLinkClick}
                  >
                    <Link to="/bookings/packages">Add Packages</Link>
                  </li>
                  <li
                    className={isActive("/bookings/slots") ? "active" : ""}
                    onClick={handleLinkClick}
                  >
                    <Link to="/bookings/slots">Add Slots</Link>
                  </li>
                  <li
                    className={isActive("/bookings/questions") ? "active" : ""}
                    onClick={handleLinkClick}
                  >
                    <Link to="/bookings/questions">
                      Add Registration Questions
                    </Link>
                  </li>
                  <li
                    className={isActive("/bookings/settings") ? "active" : ""}
                    onClick={handleLinkClick}
                  >
                    <Link to="/bookings/settings">Settings</Link>
                  </li>
                </ul>
              )}
            </li>
            {/* <li
              className={`${
                isActive("/settings") || isSettingsOpen ? "open" : ""
              }`}
            >
              <div className="menu-item" onClick={toggleSettingsMenu}>
                <span className="icon">
                  <img src={settingsIcon} alt="Settings Icon" />
                </span>
                Settings
                <span className={`arrow ${isSettingsOpen ? "open" : ""}`}>
                  <img src={arrowright} alt="arrow right" />
                </span>
              </div>
              {(isSettingsOpen || isActive("/settings")) && (
                <ul className="submenu">
                  <li
                    className={isActive("/settings/booking") ? "active" : ""}
                    onClick={handleLinkClick}
                  >
                    <Link to="/settings/booking">Booking</Link>
                  </li>
                </ul>
              )}
            </li> */}
          </ul>
        </nav>

        {/* Profile Section */}
        <div className="profile-section">
          <FontAwesomeIcon icon={faUser} className="profile-pic" />
          <div className="profile-info">
            <p className="name">{expertName}</p>
            {/* <p className="title">Self care expert</p> */}
          </div>
        </div>
        <Button
          text="Logout"
          marginBottom="20%"
          backgroundColor="#D11A2A"
          onClick={handleLogout}
        />
        {showConfirmModal && (
          <Modal
            title="Confirm Logout"
            onClose={cancelLogout} // Close modal on cancel
          >
            <div>Are you sure you want to Logout?</div>
            <div className="confirmation-buttons">
              <Button
                text="cancel"
                backgroundColor="#ccc"
                onClick={cancelLogout}
              ></Button>
              <Button
                text="Logout"
                backgroundColor="#D11A2A "
                onClick={confirmLogout}
              ></Button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Sidebar;
