import React, { useEffect, useState } from "react";
import SideBarDialog from "../../../components/Dialog/SideBarDialog";
import InputField from "../../../components/fields/InputField";
import TextAreaField from "../../../components/fields/RichTextAreaField";
import DropdownField from "../../../components/fields/DropdownField";
import SubmitButton from "../../../components/Button/SubmitButton";
import NumberField from "../../../components/fields/NumberField";
import CustomCheckbox from "../../../components/fields/CustomCheckbox";
import Cookies from "js-cookie";
import { formatDuration } from "../../../Utils/helper";
import {
  open_api_with_user_token,
  open_api_without_auth,
} from "../../../Utils/network";

const AddPackage = ({
  onClose,
  onSubmitStatus,
  parentFormData,
  isEditMode,
}) => {
  const [durationList, setDurationList] = useState([]);
  const [durationMap, setDurationMap] = useState({});
  const [formData, setFormData] = useState(parentFormData);
  const [isUserInfoRequired, setIsUserInfoRequired] = useState(
    parentFormData.is_user_data_required || false
  );
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    getDurationList();
  }, []);

  useEffect(() => {
    if (isEditMode && parentFormData.duration) {
      setFormData((prev) => ({ ...prev, duration: parentFormData.duration }));
    }
  }, [isEditMode, parentFormData.duration]);

  const getDurationList = async () => {
    try {
      const res = await open_api_without_auth.get("/session/duration_list");
      const durationResultList = res.data.results;
      setDurationList(durationResultList);

      const durationNameToIdMap = {};
      durationResultList.forEach((item) => {
        durationNameToIdMap[item.duration_name] = item.id;
      });
      setDurationMap(durationNameToIdMap);
    } catch (error) {
      console.error("Error fetching duration list:", error);
      onSubmitStatus &&
        onSubmitStatus("failure", "Error fetching duration list");
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePackageChange = (content) => {
    setFormData({ ...formData, aboutPackage: content });
  };

  const handleDropdownChange = (selectedValue) => {
    setFormData({ ...formData, duration: selectedValue });
  };

  const handleCheckboxChange = () => {
    setIsUserInfoRequired((prev) => !prev);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.sessionName) errors.sessionName = "Session name is required";
    if (!formData.sessionsPerPackage)
      errors.sessionsPerPackage = "Number of sessions is required";
    if (!formData.duration) errors.duration = "Duration is required";
    if (!formData.priceInr) errors.priceInr = "Price in INR is required";
    if (!formData.priceUsd) errors.priceUsd = "Price in USD is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      onSubmitStatus &&
        onSubmitStatus("failure", "Please fill out all required fields");
      return;
    }

    const response = await addNewPackage();
    if (response === "success") {
      onSubmitStatus &&
        onSubmitStatus(
          "success",
          `Package ${isEditMode ? "updated" : "added"} successfully`
        );
      onClose();
    } else {
      onSubmitStatus &&
        onSubmitStatus(
          "failure",
          `Failed to ${isEditMode ? "update" : "add"} package`
        );
    }
  };

  const addNewPackage = async () => {
    try {
      const expertID = Cookies.get("expertID");
      const durationId = durationMap[formData.duration];
      const localData = {
        title: formData.sessionName,
        sessions_count: formData.sessionsPerPackage,
        about_package: formData.aboutPackage,
        mrp: formData.priceInr,
        selling_price: formData.priceInr,
        mrp_usd: formData.priceUsd,
        selling_price_usd: formData.priceUsd,
        slot_duration_id: durationId,
        expert_id: expertID,
        duration: formatDuration(formData.duration),
        published: true,
        is_user_data_required: isUserInfoRequired,
      };

      let res;

      if (isEditMode && formData.id) {
        res = await open_api_with_user_token.patch(
          `/session/all-packages/${formData.id}/`,
          localData
        );
      } else {
        res = await open_api_with_user_token.post(
          "/session/all-packages/",
          localData
        );
      }

      if (res.status === 200 || res.status === 201) {
        return "success";
      } else {
        return "failure";
      }
    } catch (error) {
      console.error(
        `Error ${isEditMode ? "updating" : "adding"} package:`,
        error
      );
      return "failure";
    }
  };

  return (
    <SideBarDialog
      isVisible={true}
      onClose={onClose}
      title={`${isEditMode ? "Edit" : "Add"} Session Package`}
      footer={<SubmitButton text="Submit" onClick={handleSubmit} />}
    >
      <InputField
        label="Name of the session package"
        value={formData.sessionName}
        name="sessionName"
        onChange={handleChange}
        error={formErrors.sessionName}
      />

      <TextAreaField
        label="About the package"
        value={formData.aboutPackage}
        name="aboutPackage"
        onChange={(content) => handlePackageChange(content)}
        error={formErrors.aboutPackage}
      />

      <NumberField
        label="Number of sessions per package"
        value={formData.sessionsPerPackage}
        name="sessionsPerPackage"
        onChange={handleChange}
        error={formErrors.sessionsPerPackage}
      />

      <DropdownField
        label="Duration of each slot"
        options={durationList.map((item) => item.duration_name)}
        value={formData.duration}
        onChange={handleDropdownChange}
        error={formErrors.duration}
      />

      <NumberField
        label="Price in INR"
        value={formData.priceInr}
        name="priceInr"
        onChange={handleChange}
        error={formErrors.priceInr}
      />

      <NumberField
        label="Price in USD"
        value={formData.priceUsd}
        name="priceUsd"
        onChange={handleChange}
        error={formErrors.priceUsd}
      />

      <CustomCheckbox
        checked={isUserInfoRequired}
        onChange={handleCheckboxChange}
        title="Is user information required?"
      />
    </SideBarDialog>
  );
};

export default AddPackage;
